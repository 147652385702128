import { getBrowserSpecificProperties, getGameDataFromUrl } from "../helpers/utility";

/* images */
// cubes
import cube from "../assets/images/cube/cube.png";
import cube2 from "../assets/images/cube/2.png";
import cube4 from "../assets/images/cube/4.png";
import cube8 from "../assets/images/cube/8.png";
import cube16 from "../assets/images/cube/16.png";
import cube32 from "../assets/images/cube/32.png";
import cube64 from "../assets/images/cube/64.png";
// taken stones
import takenStonesType1Dark from "../assets/images/taken-stones/type1/dark.png";
import takenStonesType1Light from "../assets/images/taken-stones/type1/light.png";

import takenStonesType2Dark from "../assets/images/taken-stones/type2/dark.png";
import takenStonesType2Light from "../assets/images/taken-stones/type2/light.png";

import takenStonesType3Dark from "../assets/images/taken-stones/type3/dark.png";
import takenStonesType3Light from "../assets/images/taken-stones/type3/light.png";

import takenStonesType4Dark from "../assets/images/taken-stones/type4/dark.png";
import takenStonesType4Light from "../assets/images/taken-stones/type4/light.png";
// stones
// import stoneType1Dark from "../assets/images/stones/type1/dark.png";
// import stoneType1Light from "../assets/images/stones/type1/light.png";
//
// import stoneType2Dark from "../assets/images/stones/type2/dark.png";
// import stoneType2Light from "../assets/images/stones/type2/light.png";
//
// import stoneType3Dark from "../assets/images/stones/type3/dark.png";
// import stoneType3Light from "../assets/images/stones/type3/light.png";
//
// import stoneType4Dark from "../assets/images/stones/type4/dark.png";
// import stoneType4Light from "../assets/images/stones/type4/light.png";
// dices
import diceBlack1 from "../assets/images/dices/black/1.png";
import diceBlack2 from "../assets/images/dices/black/2.png";
import diceBlack3 from "../assets/images/dices/black/3.png";
import diceBlack4 from "../assets/images/dices/black/4.png";
import diceBlack5 from "../assets/images/dices/black/5.png";
import diceBlack6 from "../assets/images/dices/black/6.png";

import diceRed1 from "../assets/images/dices/red/1.png";
import diceRed2 from "../assets/images/dices/red/2.png";
import diceRed3 from "../assets/images/dices/red/3.png";
import diceRed4 from "../assets/images/dices/red/4.png";
import diceRed5 from "../assets/images/dices/red/5.png";
import diceRed6 from "../assets/images/dices/red/6.png";

import diceWhite1 from "../assets/images/dices/white/1.png";
import diceWhite2 from "../assets/images/dices/white/2.png";
import diceWhite3 from "../assets/images/dices/white/3.png";
import diceWhite4 from "../assets/images/dices/white/4.png";
import diceWhite5 from "../assets/images/dices/white/5.png";
import diceWhite6 from "../assets/images/dices/white/6.png";
import {
    TYPE_1_DARK,
    TYPE_1_LIGHT,
    TYPE_2_DARK,
    TYPE_2_LIGHT,
    TYPE_3_DARK,
    TYPE_3_LIGHT,
    TYPE_4_DARK,
    TYPE_4_LIGHT
} from "./images";
import {
    SET_CONFIRM_PLAYED_DATA,
    SET_CUBE_ACCEPTED_DATA,
    SET_CUBE_PLAYED_DATA,
    SET_DOUBLE_STAKE_ACCEPT_DATA,
    SET_DOUBLE_STAKE_PLAYED_DATA,
    SET_END_GAME_DATA,
    SET_LEAVE_MATCH_REQUEST_DATA,
    SET_MOVE_PLAYED_DATA,
    SET_NEW_ROUND_DATA
} from "../store/actions/actionTypes";

export const APP_PROPERTIES = {
    DEFAULT_ASPECT_RATIO_WIDTH: 16,
    DEFAULT_ASPECT_RATIO_HEIGHT: 9,
    FONT_SIZE_SCALE_INDEX: 170
};

export const {
    isDemo,
    isMobile,
    gameId,
    token,
    lang,
    consumerPartnerId,
    currencyId,
    win,
    bet,
    playerId,
    isHistory,
    guestId,
    isBot,
    platformId
} = getGameDataFromUrl();
export const { HIDDEN, VISIBILITY_CHANGE } = getBrowserSpecificProperties();

// various constants
export const MOUSE_LEFT_BUTTON_ID = 0;
export const MOUSE_MIDDLE_BUTTON_ID = 1;
export const MOUSE_RIGHT_BUTTON_ID = 2;
export const BOARD_STONE_COLUMNS_COUNT = 24;
export const HALF_BOARD_STONE_COLUMNS_COUNT = 12;
export const QUARTER_BOARD_STONE_COLUMNS_COUNT = 6;
export const STONES_DISTANCE_PERCENT = 100;
export const PLAYER_HIT_STONE_INDEX = 0;
export const OPPONENT_HIT_STONE_INDEX = 1;
export const HIT_STONE_COLUMN_NUMBER = 101;
export const TAKEN_STONE_COLUMN_NUMBER = 100;
export const MAX_STONE_COUNTS_IN_COLUMN = 5;
export const INITIAL_STATE = "initialState";
export const GAME_DATA = "gameData";
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const DARK = "DARK";
export const LIGHT = "LIGHT";

export const DEVICE_MODE = {
    DESKTOP: "desktop",
    MOBILE: "mobile"
};

export const SOCKET_ACTIONS = {
    GAME_DATA: "GameData",
    INITIAL_STATE: "InitialState",
    MOVE_PLAYED: "MovePlayed",
    CUBE_PLAYED: "CubePlayed",
    CUBE_REDOUBLED: "CubeRedoubled",
    CUBE_ACCEPTED: "CubeAccepted",
    DOUBLE_STAKE_PLAYED: "DoubleStakePlayed",
    DOUBLE_STAKE_CHANGED: "DoubleStakeChanged",
    CHANGE_DICE_FAILED: "ChangeDiceFailed",
    CONFIRM: "SubmitPlayedHandler",
    ROLL: "RollPlayedHandler",
    GAME_END: "GameEndedHandler",
    ROUND_STARTED: "RoundStartedHandler",
    PLAYER_CONFIGURATION_CHANGED: "PlayerConfigurationChanged",
    CONNECTION_CHANGED: "ConnectionChangedHandler",
    LEAVE_MATCH_PLAYED: "LeaveRoundPlayedHandler",
    LEAVE_MATCH_REJECTED: "LeaveRoundRejected",
    UNDO: "UndoPlayed",
    SWITCHED_BOT: "SwitchedBot",
    INET_SPEED: "InetSpeed"
};

export const TURN_CHANGE_MESSAGES = {
    [SET_MOVE_PLAYED_DATA]: true,
    [SET_CUBE_PLAYED_DATA]: true,
    [SET_CUBE_ACCEPTED_DATA]: true,
    [SET_DOUBLE_STAKE_PLAYED_DATA]: true,
    [SET_DOUBLE_STAKE_ACCEPT_DATA]: true,
    [SET_CONFIRM_PLAYED_DATA]: true,
    [SET_NEW_ROUND_DATA]: true,
    [SET_LEAVE_MATCH_REQUEST_DATA]: true,
    [SET_END_GAME_DATA]: true
};

export const SOCKET_INVOKE_ACTIONS = {
    GET_INITIAL_STATE: "GetInitialState",
    GET_GAME_DATA: "GetGameData",
    LEAVE_GAME: "LeaveGame",
    PLAY_MOVE: "PlayMove",
    CUBE_REQUEST: "CubeRequest",
    CUBE_RESPONSE: "CubeResponse",
    DOUBLE_STAKE_REQUEST: "DoubleStakeRequest",
    DOUBLE_STAKE_RESPONSE: "DoubleStakeResponse",
    CHANGE_DICE: "ChangeDice",
    ROLL: "Roll",
    UNDO: "Undo",
    SUBMIT: "Submit",
    CHANGE_PLAYER_CONFIGURATION: "PlayerConfigurationChange",
    LEAVE_MATCH_REQUEST: "LeaveRoundRequest",
    LEAVE_MATCH_RESPONSE: "LeaveRoundResponse",
    PLAYER_SOW_DECLINED_POPUP: "LeaveRoundRejectPopupWasSeen",
    SWITCH_BOT: "SwitchBot",
    GET_INET_SPEED: "GetInetSpeed"
};

export const CUBE_REQUESTS = {
    ACCEPT: "Accept",
    REDOUBLE: "Redouble",
    DENY: "Deny"
};

export const LEAVE_MATCH_TYPES = {
    MATCH: "Normal",
    MARS: "Mars",
    KOKS: "Koks"
};

export const SS_KEYS = {
    IS_SOUND_ENABLE: "isSoundEnable",
    DICE_ANIMATED: "diceAnimated"
};

export const rankEnums = {
    seven: 1,
    eight: 2,
    nine: 3,
    ten: 4,
    jack: 5,
    queen: 6,
    king: 7,
    ace: 8
};

export const doubleStakeEnums = {
    accept: 1,
    redouble: 2,
    surrender: 3
};

export const DECK_TYPES = ["armenian", "classic", "default"];

export const GAME_ENDING_REASON = {
    1: "ToEnd",
    2: "TimeOut",
    3: "Surrender",
    4: "Canceled"
};

export const HTTP_METHODS = {
    GET: "GET",
    POST: "POST"
};

export const CUBE_IMAGES = {
    cube,
    cube2,
    cube4,
    cube8,
    cube16,
    cube32,
    cube64
};

export const STONE_TYPES = {
    type_1: {
        DARK: TYPE_1_DARK,
        LIGHT: TYPE_1_LIGHT
    },
    type_2: {
        DARK: TYPE_2_DARK,
        LIGHT: TYPE_2_LIGHT
    },
    type_3: {
        DARK: TYPE_3_DARK,
        LIGHT: TYPE_3_LIGHT
    },
    type_4: {
        DARK: TYPE_4_DARK,
        LIGHT: TYPE_4_LIGHT
    }
};

export const DOT_COUNT_CLASSES = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six"
};

export const DICE_NUMBER_CLASSES = {
    1: "show-front",
    2: "show-bottom",
    3: "show-right",
    4: "show-left",
    5: "show-top",
    6: "show-back"
};

export const DICES = {
    BLACK: {
        1: diceBlack1,
        2: diceBlack2,
        3: diceBlack3,
        4: diceBlack4,
        5: diceBlack5,
        6: diceBlack6
    },
    WHITE: {
        1: diceWhite1,
        2: diceWhite2,
        3: diceWhite3,
        4: diceWhite4,
        5: diceWhite5,
        6: diceWhite6
    },
    RED: {
        1: diceRed1,
        2: diceRed2,
        3: diceRed3,
        4: diceRed4,
        5: diceRed5,
        6: diceRed6
    }
};

export const getOpponentDiceType = (chipType, diceType) => {
    switch (true) {
        case chipType === 0 && diceType === 0:
        case chipType === 1 && diceType === 1:
            return "BLACK";
        case chipType === 0 && diceType === 2:
            return "WHITE";
        case chipType === 1 && diceType === 2:
            return "RED";
        default:
            return "BLACK";
    }
};

export const getDiceTypeByChipType = num => {
    switch (num) {
        case 0:
        case 2:
        case 3:
            return "WHITE";
        case 1:
            return "RED";
        default:
            return "WHITE";
    }
};

export const DICE_TYPE_BY_NUM = {
    0: "WHITE",
    1: "RED",
    2: "BLACK"
};

export const TAKEN_STONES_TYPES = {
    type_1: {
        DARK: takenStonesType1Dark,
        LIGHT: takenStonesType1Light
    },
    type_2: {
        DARK: takenStonesType2Dark,
        LIGHT: takenStonesType2Light
    },
    type_3: {
        DARK: takenStonesType3Dark,
        LIGHT: takenStonesType3Light
    },
    type_4: {
        DARK: takenStonesType4Dark,
        LIGHT: takenStonesType4Light
    }
};

export const GAME_TYPE_PIONER = false;

export const HISTORY_ACTION_TYPES = {
    INITIAL: 0,
    ROLL: 1,
    MOVE_STONE: 2
};

export const GAME_TYPES = {
    LONG: "Long"
};

export const FINISH_TYPE_TIME_OUT = "TimeOut";
export const VALIDATION_COOKIE_NAME = "_ga_anl_cf3q";

import { combineReducers } from "redux";
import gameData from "./gameData";
import popups from "./popups";
import gameStaticData from "./gameStaticData";
import settingsData from "./settingsData";
import translations from "./translations";
import historyData from "./historyReducer";
import demoGameData from "./demoGameData";

export default combineReducers({
    gameData,
    popups,
    gameStaticData,
    settingsData,
    translations,
    historyData,
    demoGameData
});

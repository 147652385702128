//Popup action types
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const CLOSE_ALL_POPUPS = "CLOSE_ALL_POPUPS";

//Game action types
export const SET_GAME_STATIC_DATA = "SET_GAME_STATIC_DATA";
export const SET_GAME_DATA = "SET_GAME_DATA";
export const SET_MOVE_PLAYED_DATA = "SET_MOVE_PLAYED_DATA";
export const TOGGLE_STONE_ANIMATION = "TOGGLE_STONE_ANIMATION";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const SET_CUBE_PLAYED_DATA = "SET_CUBE_PLAYED_DATA";
export const SET_CUBE_ACCEPTED_DATA = "SET_CUBE_REDOUBLED_DATA";
export const SET_DOUBLE_STAKE_PLAYED_DATA = "SET_DOUBLE_STAKE_PLAYED_DATA";
export const SET_DOUBLE_STAKE_ACCEPT_DATA = "SET_DOUBLE_STAKE_ACCEPT_DATA";
export const SET_STONE_FROM_COLUMN = "SET_STONE_FROM_COLUMN";
export const SET_STONE_TO_COLUMN = "SET_STONE_TO_COLUMN";
export const SET_SELECTED_STONE_POSSIBLE_MOVES = "SET_SELECTED_STONE_POSSIBLE_MOVES";
export const SET_CONFIRM_PLAYED_DATA = "SET_CONFIRM_PLAYED_DATA";
export const SET_ROLL_PLAYED_DATA = "SET_ROLL_PLAYED_DATA";
export const SET_NEW_ROUND_DATA = "SET_NEW_ROUND_DATA";
export const SET_END_GAME_DATA = "SET_END_GAME_DATA";
export const SET_CONFIGURATION_DATA = "SET_CONFIGURATION_DATA";
export const SET_CONNECTION_DATA = "SET_CONNECTION_DATA";
export const SET_LEAVE_MATCH_REQUEST_DATA = "SET_LEAVE_MATCH_REQUEST_DATA";
export const SET_UNDO_DATA = "SET_UNDO_DATA";
export const SET_ROLLED_NOW = "SET_ROLLED_NOW";

//Settings action types
export const SET_SETTINGS_DATA = "SET_SETTINGS_DATA";
export const CHANGE_SETTINGS = "CHANGE_SETTINGS";

export const SAVE_SWITCH_MODE = "SAVE_SWITCH_MODE";
export const SAVE_LOADING = "SAVE_LOADING";

//Game history action types
export const SET_HISTORY_DATA = "SET_HISTORY_DATA";
export const SET_CURRENT_ROUND_ID = "SET_CURRENT_ROUND_ID";
export const SET_CURRENT_STEP_ID = "SET_CURRENT_STEP_ID";
export const SET_CURRENT_MOVE_ID = "SET_CURRENT_MOVE_ID";

import { CLOSE_ALL_POPUPS, CLOSE_POPUP, OPEN_POPUP } from "../actions/actionTypes";

const initialState = [];

export default (state = initialState, action) => {
    const { name, data, type } = action;
    switch (type) {
        case OPEN_POPUP:
            return state.find(popup => popup.name === name) ? state : state.concat({ name, data });
        case CLOSE_POPUP:
            return name ? state.filter(popup => popup.name !== name) : state.slice(0, state.length - 1);
        case CLOSE_ALL_POPUPS:
            return [];
        default:
            return state;
    }
};

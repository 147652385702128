import { SAVE_SWITCH_MODE, SAVE_LOADING } from "../actions/actionTypes";

const initialState = {
    isSwitched: false,
    loading: false
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case SAVE_SWITCH_MODE:
            return { ...state, ...action.payload };
        case SAVE_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

import React, { useState, Suspense, lazy } from "react";
import { isDemo } from "./constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AppData = lazy(() => import("./containers/AppData"));
const DemoStartButton = lazy(() => import("./components/DemoStartButton"));

const AppIndex = ({ isDemoGame, gameFinishType }) => {
    const [isStartDemo] = useState(isDemo);

    return (
        <Suspense fallback={null}>
            {isStartDemo || (isDemoGame && !!gameFinishType) ? <DemoStartButton /> : <AppData />}
        </Suspense>
    );
};

const mapStateToProps = state => ({
    isDemoGame: state.gameStaticData?.gameConfiguration?.isDemo,
    gameFinishType: state.gameData.gameFinishType
});

AppIndex.propTypes = {
    gameFinishType: PropTypes.string,
    isDemoGame: PropTypes.bool
};

AppIndex.defaultProps = {
    gameFinishType: null,
    isDemoGame: false
};

export default connect(mapStateToProps)(AppIndex);

import {
    CLOSE_POPUP,
    OPEN_POPUP,
    SET_GAME_DATA,
    CLOSE_ALL_POPUPS,
    SET_GAME_STATIC_DATA,
    SET_TRANSLATIONS,
    SET_MOVE_PLAYED_DATA,
    SET_SELECTED_STONE_POSSIBLE_MOVES,
    SET_CUBE_PLAYED_DATA,
    SET_CUBE_ACCEPTED_DATA,
    SET_DOUBLE_STAKE_PLAYED_DATA,
    SET_STONE_FROM_COLUMN,
    SET_STONE_TO_COLUMN,
    SET_DOUBLE_STAKE_ACCEPT_DATA,
    SET_CONFIRM_PLAYED_DATA,
    SET_ROLL_PLAYED_DATA,
    TOGGLE_STONE_ANIMATION,
    SET_NEW_ROUND_DATA,
    SET_END_GAME_DATA,
    SET_CONFIGURATION_DATA,
    SET_CONNECTION_DATA,
    SET_LEAVE_MATCH_REQUEST_DATA,
    SET_HISTORY_DATA,
    SET_CURRENT_ROUND_ID,
    SET_CURRENT_STEP_ID,
    SET_SETTINGS_DATA,
    CHANGE_SETTINGS,
    SET_CURRENT_MOVE_ID,
    SET_UNDO_DATA,
    SET_ROLLED_NOW,
    SAVE_SWITCH_MODE,
    SAVE_LOADING
} from "./actionTypes";

//Popups actions
export const openPopup = (name, data) => ({
    type: OPEN_POPUP,
    name,
    data
});

export const closePopup = name => ({
    type: CLOSE_POPUP,
    name
});
export const closeAllPopup = () => ({
    type: CLOSE_ALL_POPUPS
});

//Game actions
export const setGameStaticData = payload => ({
    type: SET_GAME_STATIC_DATA,
    payload
});

export const setGameData = payload => ({
    type: SET_GAME_DATA,
    payload
});

export const setMovePlayedData = payload => ({
    type: SET_MOVE_PLAYED_DATA,
    payload
});

export const saveSwitchMode = payload => ({
    type: SAVE_SWITCH_MODE,
    payload
});

export const saveLoading = payload => ({
    type: SAVE_LOADING,
    payload
});

export const toggleStoneAnimation = payload => ({
    type: TOGGLE_STONE_ANIMATION,
    payload
});

export const setSelectedStonePossibleMoves = payload => ({
    type: SET_SELECTED_STONE_POSSIBLE_MOVES,
    payload
});

export const setStoneToColumn = payload => ({
    type: SET_STONE_TO_COLUMN,
    payload
});

export const setStoneFromColumn = payload => ({
    type: SET_STONE_FROM_COLUMN,
    payload
});

export const setTranslations = payload => ({
    type: SET_TRANSLATIONS,
    payload
});

export const setCubePlayedData = payload => ({
    type: SET_CUBE_PLAYED_DATA,
    payload
});

export const setCubeAcceptedData = payload => ({
    type: SET_CUBE_ACCEPTED_DATA,
    payload
});

export const setDoubleStakePlayedData = payload => ({
    type: SET_DOUBLE_STAKE_PLAYED_DATA,
    payload
});
export const setDoubleStakeAcceptData = payload => ({
    type: SET_DOUBLE_STAKE_ACCEPT_DATA,
    payload
});
export const setConfirmPlayedData = payload => ({
    type: SET_CONFIRM_PLAYED_DATA,
    payload
});
export const setRollPlayedData = payload => ({
    type: SET_ROLL_PLAYED_DATA,
    payload
});
export const setNewRoundData = payload => ({
    type: SET_NEW_ROUND_DATA,
    payload
});
export const setEndGameData = payload => ({
    type: SET_END_GAME_DATA,
    payload
});
export const setConfigurationData = payload => ({
    type: SET_CONFIGURATION_DATA,
    payload
});

export const setConnectionData = payload => ({
    type: SET_CONNECTION_DATA,
    payload
});
export const setLeaveMatchRequestData = (payload, bool = false) => ({
    type: SET_LEAVE_MATCH_REQUEST_DATA,
    payload,
    bool
});
export const setUndoData = payload => ({
    type: SET_UNDO_DATA,
    payload
});
export const setRolledNow = payload => ({
    type: SET_ROLLED_NOW,
    payload
});

//Settings actions
export const setSettingsData = payload => ({
    type: SET_SETTINGS_DATA,
    payload
});

export const changeSettings = (key, index) => ({
    type: CHANGE_SETTINGS,
    key,
    index
});

//Game History action types

export const setHistoryData = payload => ({
    type: SET_HISTORY_DATA,
    payload
});

export const setCurrentRound = payload => ({
    type: SET_CURRENT_ROUND_ID,
    payload
});

export const setCurrentStep = payload => ({
    type: SET_CURRENT_STEP_ID,
    payload
});

export const setCurrentMove = payload => ({
    type: SET_CURRENT_MOVE_ID,
    payload
});

import { CHANGE_SETTINGS, SET_SETTINGS_DATA } from "../actions/actionTypes";

export default (state = null, action) => {
    const { type, payload, key, index } = action;
    switch (type) {
        case SET_SETTINGS_DATA:
            return payload;
        case CHANGE_SETTINGS:
            return { ...state, [key]: { ...state[key], currentSettingIndex: index } };
        default:
            return state;
    }
};

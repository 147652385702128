import {
    BOARD_STONE_COLUMNS_COUNT,
    HALF_BOARD_STONE_COLUMNS_COUNT,
    HISTORY_ACTION_TYPES,
    HIT_STONE_COLUMN_NUMBER,
    isMobile,
    MAX_STONE_COUNTS_IN_COLUMN,
    MOUSE_LEFT_BUTTON_ID,
    MOUSE_MIDDLE_BUTTON_ID,
    MOUSE_RIGHT_BUTTON_ID,
    playerId,
    QUARTER_BOARD_STONE_COLUMNS_COUNT,
    SOCKET_INVOKE_ACTIONS,
    STONES_DISTANCE_PERCENT,
    TAKEN_STONE_COLUMN_NUMBER
} from "../constants";
import { invoke } from "../connection";
import { store } from "../store";
import {
    setCurrentMove,
    setCurrentRound,
    setCurrentStep,
    setMovePlayedData
    // setRollPlayedData
} from "../store/actions";
import { batchActions } from "redux-batched-actions";
import { getDomain } from "./utility";
import { getTranslations } from "./translation";

function collectValidMovesByDices(dice1, dice2, allValidMoves) {
    const bigDice = {
        value: dice1 >= dice2 ? dice1 : dice2,
        moves: findMoves(dice1 >= dice2 ? dice1 : dice2, allValidMoves)
    };
    const smallDice = {
        value: dice1 >= dice2 ? dice2 : dice1,
        moves: findMoves(dice1 >= dice2 ? dice2 : dice1, allValidMoves)
    };

    const bothDices = {
        value: dice1 + dice2,
        moves: findNextMoves(allValidMoves)
    };

    return {
        bigDice,
        smallDice,
        bothDices
    };
}

export function findMoves(dice, allValidMoves) {
    const moves = {};
    for (let key in allValidMoves) {
        if (allValidMoves.hasOwnProperty(key)) {
            const move = allValidMoves[key];
            if (move.dice === dice && !move.nextMove) {
                moves[key] = {
                    from: move.from,
                    to: move.to
                };
            }
        }
    }

    return moves;
}

export const findSelectedStonePossibleMoves = (selectedColumn, allPossibleMoves) => {
    const moves = {};
    for (let key in allPossibleMoves) {
        if (allPossibleMoves.hasOwnProperty(key)) {
            const move = allPossibleMoves[key];
            if (move.from === selectedColumn) {
                if (move?.nextMove) {
                    moves[move.nextMove.to] = key;
                } else {
                    moves[move.to] = key;
                }
            }
        }
    }

    return moves;
};

export function findNextMoves(allMoves, dice = null) {
    const moves = {};
    for (let key in allMoves) {
        if (allMoves.hasOwnProperty(key)) {
            const move = allMoves[key];
            if (move.nextMove && !moves[key] && (!dice || dice === move.dice)) {
                moves[key] = {
                    from: move.from,
                    to: move.nextMove.to
                };
            }
        }
    }

    return moves;
}

function syncFirstDicesInfo(dicesInfo, playerFirstDice, opponentFirstDice) {
    dicesInfo.dice1 = playerFirstDice;
    dicesInfo.dice2 = opponentFirstDice;
}

export function handleDiceData(dicesInfo, player, opponentFirstDice, dontShowPossMoves, oldDice, dices) {
    //
    if (dicesInfo) {
        dicesInfo = { ...dicesInfo };
        const { moves, firstDice } = player;
        firstDice && !oldDice && syncFirstDicesInfo(dicesInfo, firstDice, opponentFirstDice);
        let validMoves = collectValidMovesByDices(dicesInfo.dice1, dicesInfo.dice2, moves);

        checkPlayedDice(
            validMoves.bigDice,
            validMoves.smallDice,
            dices,
            dicesInfo,
            validMoves.bigDice?.value === dicesInfo.dice1
        );
        dicesInfo.bigDice = validMoves.bigDice;
        dicesInfo.smallDice = validMoves.smallDice;
        dicesInfo.bothDices = validMoves.bothDices;
    }
    return dicesInfo;
}

function checkPlayedDice(bigDice, smallDice, dices, dicesInfo, isBigDiceTheFirst) {
    if (dices?.length) {
        if (dicesInfo.isDouble) {
            switch (dices.length) {
                case 1:
                    dicesInfo["firstDiceClassName"] = "played";
                    bigDice.isPlayed = true;
                    dicesInfo["secondDiceClassName"] = "half-played";
                    break;
                case 2:
                    dicesInfo["firstDiceClassName"] = "played";
                    smallDice.isPlayed = true;
                    break;
                case 3:
                    dicesInfo["firstDiceClassName"] = "half-played";
                    break;
                default:
                    break;
            }
        } else if (!dices?.includes(dicesInfo.dice1)) {
            dicesInfo["firstDiceClassName"] = "played";
        } else if (!dices?.includes(dicesInfo.dice2)) {
            dicesInfo["secondDiceClassName"] = "played";
        }

        if (dicesInfo[`${isBigDiceTheFirst ? "firstDiceClassName" : "secondDiceClassName"}`] === "played") {
            bigDice.isPlayed = true;
        } else if (dicesInfo[`${isBigDiceTheFirst ? "secondDiceClassName" : "firstDiceClassName"}`] === "played") {
            smallDice.isPlayed = true;
        }
    } else {
        dicesInfo["firstDiceClassName"] = "played";
        dicesInfo["secondDiceClassName"] = "played";
    }
}

export function findMoveIndex(possibleMoves, fromColumn, dice) {
    for (let key in possibleMoves) {
        if (possibleMoves.hasOwnProperty(key)) {
            const move = possibleMoves[key];
            if (
                move.from === fromColumn &&
                (move.from === HIT_STONE_COLUMN_NUMBER ||
                    move.to === move.from + dice ||
                    move.to === TAKEN_STONE_COLUMN_NUMBER)
            ) {
                return key;
            }
        }
    }
}

export function setAppReady(resolve) {
    let firstDataLoaded = null;
    return (key, data) => {
        if (firstDataLoaded) {
            firstDataLoaded[key] = data;
            resolve(firstDataLoaded);
        } else {
            firstDataLoaded = { [key]: data };
        }
    };
}

export function preventDefaults() {
    let leftButtonDown = false;
    let rightButtonDown = false;

    document.oncontextmenu = e => {
        if (leftButtonDown && rightButtonDown) {
            e.stopPropagation();
        }
        leftButtonDown = false;
        rightButtonDown = false;
        e.preventDefault();
    };

    document.onmousedown = e => {
        if (e.button === MOUSE_MIDDLE_BUTTON_ID) {
            e.preventDefault();
        } else if (e.button === MOUSE_LEFT_BUTTON_ID) {
            leftButtonDown = true;
        } else if (e.button === MOUSE_RIGHT_BUTTON_ID) {
            rightButtonDown = true;
        }
    };

    document.addEventListener(
        "click",
        e => {
            if (leftButtonDown && rightButtonDown) {
                leftButtonDown = false;
                rightButtonDown = false;
                e.stopPropagation();
                e.preventDefault();
            }
        },
        true
    );
}

export function getStoneAnimationStyles(fromColumn, fromColumnStoneCount, toColumn, toColumnStoneCount) {
    const originalToComunStoneCount = toColumnStoneCount;

    if (toColumnStoneCount < 0) {
        if (toColumnStoneCount === -1 && fromColumnStoneCount > 0) {
            toColumnStoneCount = 0;
        } else {
            toColumnStoneCount = Math.abs(toColumnStoneCount);
        }
    }
    if (fromColumnStoneCount < 0) {
        if (originalToComunStoneCount === 1) {
            toColumnStoneCount = 0;
        }
        fromColumnStoneCount = Math.abs(fromColumnStoneCount);
    }

    let xCoordinate = 0,
        yCoordinate = 0,
        toAndFromColumnStoneCountsDiff = toColumnStoneCount - fromColumnStoneCount;

    if (fromColumn < HALF_BOARD_STONE_COLUMNS_COUNT && toColumn < HALF_BOARD_STONE_COLUMNS_COUNT) {
        yCoordinate =
            (toAndFromColumnStoneCountsDiff >= MAX_STONE_COUNTS_IN_COLUMN
                ? toAndFromColumnStoneCountsDiff
                : Math.abs(toAndFromColumnStoneCountsDiff + 1)) *
            (toAndFromColumnStoneCountsDiff + 1 >= 0 ? STONES_DISTANCE_PERCENT : -STONES_DISTANCE_PERCENT);

        if (toColumnStoneCount >= MAX_STONE_COUNTS_IN_COLUMN) {
            yCoordinate -= STONES_DISTANCE_PERCENT;
        }

        if (fromColumn < QUARTER_BOARD_STONE_COLUMNS_COUNT && toColumn >= QUARTER_BOARD_STONE_COLUMNS_COUNT) {
            xCoordinate = -67;
        } else if (fromColumn >= QUARTER_BOARD_STONE_COLUMNS_COUNT && toColumn < QUARTER_BOARD_STONE_COLUMNS_COUNT) {
            xCoordinate = 67;
        }
        xCoordinate += (toColumn - fromColumn) * -100;
    } else if (fromColumn >= HALF_BOARD_STONE_COLUMNS_COUNT && toColumn >= HALF_BOARD_STONE_COLUMNS_COUNT) {
        yCoordinate =
            (toAndFromColumnStoneCountsDiff >= MAX_STONE_COUNTS_IN_COLUMN
                ? toAndFromColumnStoneCountsDiff
                : Math.abs(toAndFromColumnStoneCountsDiff + 1)) *
            (toAndFromColumnStoneCountsDiff + 1 <= 0 ? STONES_DISTANCE_PERCENT : -STONES_DISTANCE_PERCENT);

        if (toColumnStoneCount >= MAX_STONE_COUNTS_IN_COLUMN) {
            yCoordinate += STONES_DISTANCE_PERCENT;
        }

        if (
            fromColumn < BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT &&
            toColumn >= BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT
        ) {
            xCoordinate = 67;
        } else if (
            fromColumn >= BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT &&
            toColumn < BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT
        ) {
            xCoordinate = -67;
        }
        xCoordinate += (toColumn - fromColumn) * 100;
    } else {
        yCoordinate =
            ((isMobile ? 0.5 : 2) +
                ((MAX_STONE_COUNTS_IN_COLUMN - fromColumnStoneCount) * STONES_DISTANCE_PERCENT +
                    (toColumnStoneCount === MAX_STONE_COUNTS_IN_COLUMN
                        ? 1
                        : MAX_STONE_COUNTS_IN_COLUMN - toColumnStoneCount) *
                        STONES_DISTANCE_PERCENT) +
                200) *
            (fromColumn >= HALF_BOARD_STONE_COLUMNS_COUNT ? -1 : 1);

        if (fromColumn < toColumn) {
            xCoordinate = toColumn - HALF_BOARD_STONE_COLUMNS_COUNT - (HALF_BOARD_STONE_COLUMNS_COUNT - 1 - fromColumn);
        } else {
            xCoordinate = HALF_BOARD_STONE_COLUMNS_COUNT - 1 - toColumn - (fromColumn - HALF_BOARD_STONE_COLUMNS_COUNT);
        }

        xCoordinate *= 100;
        if (
            (fromColumn < HALF_BOARD_STONE_COLUMNS_COUNT &&
                fromColumn >= QUARTER_BOARD_STONE_COLUMNS_COUNT &&
                toColumn >= BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT) ||
            (fromColumn >= HALF_BOARD_STONE_COLUMNS_COUNT &&
                fromColumn < BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT &&
                toColumn < QUARTER_BOARD_STONE_COLUMNS_COUNT)
        ) {
            xCoordinate += 67;
        } else if (
            (fromColumn >= BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT &&
                toColumn < HALF_BOARD_STONE_COLUMNS_COUNT &&
                toColumn >= QUARTER_BOARD_STONE_COLUMNS_COUNT) ||
            (fromColumn < QUARTER_BOARD_STONE_COLUMNS_COUNT &&
                toColumn >= HALF_BOARD_STONE_COLUMNS_COUNT &&
                toColumn < BOARD_STONE_COLUMNS_COUNT - QUARTER_BOARD_STONE_COLUMNS_COUNT)
        ) {
            xCoordinate += -67;
        }
    }
    const rotateBoard = store.getState().gameData?.playerConfiguration?.rotateBoard;
    rotateBoard && (xCoordinate *= -1);

    return { transform: `translate(${xCoordinate}%, ${yCoordinate}%)`, zIndex: 99 };
}

export function getTransitionEndEventName() {
    let transitions = {
        transition: "transitionend",
        OTransition: "oTransitionEnd",
        MozTransition: "transitionend",
        WebkitTransition: "webkitTransitionEnd"
    };
    let bodyStyle = document.body.style;
    for (let transition in transitions) {
        if (bodyStyle[transition] !== undefined) {
            return transitions[transition];
        }
    }
}

export const changePlayerConfiguration = (key, value, options) => {
    const { playerConfiguration } = store.getState().gameData;
    let model = {
        ...playerConfiguration,
        playerId: playerId
    };
    if (options) {
        model = { ...model, ...options };
    } else {
        model[key] = value;
    }
    invoke(SOCKET_INVOKE_ACTIONS.CHANGE_PLAYER_CONFIGURATION, model);
};

export const customMemo = (prev, next) => {
    if (next.isPlayer && next.stoneCount > 0) {
        return false;
    }

    return JSON.stringify(prev) === JSON.stringify(next);
};

export const getImageUrl = imageUrl => {
    if (process.env.REACT_APP_IS_DYNAMIC_IMAGE_URL === "false") {
        return "https://cdn-sg.deekjdsg-9q87vb3p.org" + imageUrl;
    } else {
        return "https://cdn-sg." + getDomain() + imageUrl;
    }
};

export const preloadSettingsImages = settings => {
    for (let type in settings) {
        settings[type].settingList.forEach(i => {
            let a = document.createElement("img");
            a.src = getImageUrl(i.url);
            a.remove();
        });
    }
};

export const setCurrentStepMoveHistoryData = (
    type,
    roundId = 0,
    stepId,
    moveId,
    historyData,
    isPrev,
    decrementMoveId,
    slide
) => {
    if (!historyData) {
        historyData = store.getState().historyData.data;
    }
    if (historyData) {
        let historyGameData = JSON.parse(JSON.stringify(store.getState().gameData));
        const { rounds, gameConfiguration, gameScore, playerDice, opponentDice } = historyData;
        const { cube, maxStakeDoubling } = gameConfiguration;

        const currentRound = rounds[roundId];
        const currentStep = currentRound?.stepList[stepId];
        let currentMove = currentStep && moveId > -1 && currentStep.moveList[moveId];
        let currentMoveBoard = currentMove?.board;

        if ((isPrev && !currentMoveBoard && type === HISTORY_ACTION_TYPES.ROLL) || (slide && stepId !== 0)) {
            currentMove = findNearestPreviousMove(currentRound, stepId);
            currentMoveBoard = currentMove?.board || currentRound?.board;
        }

        const { board, oldDice, isOpponentDisconnected, isPlayerDisconnected } = currentRound;

        const { stepDoubleStake, stepCube, stepLeaveRound, dices: notPlayedDices } = currentStep || {};
        const { INITIAL, ROLL, MOVE_STONE } = HISTORY_ACTION_TYPES;

        switch (type) {
            case ROLL: {
                historyGameData = {
                    ...historyGameData,
                    notPlayedDices,
                    isPlayer: currentStep.isPlayer,
                    player: {
                        ...historyGameData.player,
                        firstDice: roundId === 0 && stepId === 0 ? playerDice : null,
                        isDisconnected: !!currentStep.isPlayerDisconnected,
                        gameTime: currentStep.playerGameTime
                    },
                    opponent: {
                        ...historyGameData.opponent,
                        firstDice: roundId === 0 && stepId === 0 ? opponentDice : null,
                        isDisconnected: !!currentStep.isOpponentDisconnected,
                        gameTime: currentStep.opponentGameTime
                    },
                    dice: currentStep.dice,
                    oldDice: currentStep.oldDice, //ete null chi change dice a exel
                    dices: isPrev
                        ? []
                        : !currentStep?.moveList?.length
                        ? []
                        : currentStep.dice?.isDouble
                        ? Array(4).fill(currentStep.dice.dice1, 0, 3)
                        : [currentStep.dice?.dice1, currentStep.dice?.dice2],
                    // : currentStep.moveList.length > 1
                    // ? [currentStep.dice?.dice1, currentStep.dice?.dice2]
                    // : [Math.abs(currentStep.moveList[0].from - currentStep.moveList[0].to)],
                    changeDiceType: currentStep.oldDice && !currentStep.changedDice ? "Both" : "One",
                    changedDice: currentStep.changedDice,
                    fullMove: null,
                    boardInfo: currentMoveBoard || historyGameData.boardInfo
                };

                break;
            }
            case MOVE_STONE: {
                let newBoard = currentMove?.board;
                let newDices = currentMove?.dices;
                if (isPrev) {
                    if (moveId > 0) {
                        newBoard = currentStep.moveList[moveId - 1].board;
                        newDices = currentStep.moveList[moveId - 1].dices;
                        // } else if (stepId > 0 && !currentRound.stepList[stepId - 1].stepCube) {
                    } else if (stepId > 0) {
                        const nearestPrevStepMove = findNearestPreviousMove(currentRound, stepId);
                        newBoard = nearestPrevStepMove?.board || currentRound?.board;
                        const { dice1, dice2, isDouble } = currentStep.dice;
                        newDices = isDouble
                            ? Array(4).fill(dice1, 0, 3)
                            : currentStep.moveList.length > 1
                            ? [dice1, dice2]
                            : [Math.abs(currentStep.moveList[0].from - currentStep.moveList[0].to)];
                        // } else if (roundId > 0) {
                        //     const prevRoundStepList = rounds[roundId].stepList;
                        //     const prevRoundLastStepMoveList = prevRoundStepList[prevRoundStepList.length - 1].moveList;
                        //     newBoard = prevRoundLastStepMoveList[prevRoundLastStepMoveList.length - 1].board;
                        //     newDices = prevRoundLastStepMoveList[prevRoundLastStepMoveList.length - 1].newDices;
                    } else {
                        newBoard = board;
                        const { dice1, dice2, isDouble } = currentRound.stepList[stepId].dice;
                        newDices = isDouble ? Array(4).fill(dice1, 0, 3) : [dice1, dice2];
                    }
                }

                historyGameData = {
                    ...historyGameData,
                    notPlayedDices,
                    isPlayer: currentStep.isPlayer,
                    player: {
                        ...historyGameData.player,
                        firstDice: roundId === 0 && stepId === 0 ? playerDice : null,
                        isDisconnected: !!currentStep.isPlayerDisconnected
                    },
                    opponent: {
                        ...historyGameData.opponent,
                        firstDice: roundId === 0 && stepId === 0 ? opponentDice : null,
                        isDisconnected: !!currentStep.isOpponentDisconnected
                    },
                    boardInfo: newBoard,
                    dice: currentStep.dice,
                    oldDice: currentStep.oldDice,
                    dices: newDices,
                    changedDice: currentStep.changedDice,
                    changeDiceType: currentStep.oldDice && !currentStep.changedDice ? "Both" : "One",
                    fullMove: {
                        from: isPrev ? currentMove.to : currentMove.from,
                        to: isPrev ? currentMove.from : currentMove.to
                    }
                };

                break;
            }

            case INITIAL: {
                const { dice } = currentRound.stepList[stepId] || {};
                historyGameData = {
                    notPlayedDices,
                    isPlayer: currentStep?.isPlayer,
                    player: {
                        firstDice: roundId === 0 && playerDice,
                        isDisconnected: !!isPlayerDisconnected,
                        gameTime: gameConfiguration.gameTime
                    },
                    opponent: {
                        firstDice: roundId === 0 && opponentDice,
                        isDisconnected: !!isOpponentDisconnected,
                        gameTime: gameConfiguration.gameTime
                    },
                    boardInfo: board,
                    dice: dice,
                    oldDice: oldDice,
                    dices: dice?.isDouble ? Array(4).fill(dice?.dice1, 0, 3) : [dice?.dice1, dice?.dice2], //currentRound.stepList[0].dices,
                    changeDiceType: currentStep?.oldDice && !currentStep?.changedDice ? "Both" : "One",
                    changedDice: currentStep?.changeDice,
                    gameScore,
                    fullMove: null,
                    leaveRound: null
                };

                break;
            }
            default: {
                break;
            }
        }

        const actions = [
            setCurrentRound(roundId),
            setCurrentStep(stepId),
            setCurrentMove(decrementMoveId ? --moveId : moveId)
        ];

        let playerNotification = null;
        let opponentNotification = null;

        if (currentStep?.isPlayer) {
            playerNotification = currentStep?.notification;
            historyGameData.opponent.notification = null;
        } else {
            opponentNotification = currentStep?.notification;
            historyGameData.player.notification = null;
        }

        if (maxStakeDoubling && stepDoubleStake) {
            const { doubleStakeNumber, doubleStakeAction, isOnPlayer } = stepDoubleStake;
            if (doubleStakeNumber) {
                historyGameData.player.doubleStakeInfo = {
                    currentStake: doubleStakeNumber,
                    requestedStake: doubleStakeNumber,
                    withRedouble: doubleStakeAction === "Redouble",
                    isPlaying: true,
                    isOnPlayer: doubleStakeAction === "Accept" ? isOnPlayer : currentStep.isPlayer
                };
            }
        }

        if (cube) {
            if (stepCube) {
                const { cubeNumber, isPlaying, isPlayerCube } = stepCube;
                historyGameData.player.cubeInfo = {
                    cubeCurrentNumber: cubeNumber,
                    cubeRequestNumber: cubeNumber,
                    withRedouble: false,
                    isPlaying: isPlaying,
                    isPlayerCube: isPlayerCube
                };
            } else {
                historyGameData.player.cubeInfo = {
                    isPlayerCube: null
                };
            }
        }

        if (stepLeaveRound) {
            const { roundFinishType, response } = stepLeaveRound;
            let notification = `HistoryFinishRound${roundFinishType}`;
            if (response === false) {
                notification = "Reject";
            } else if (response) {
                notification = "Accept";
            }
            if (currentStep.isPlayer) {
                playerNotification = notification;
            } else {
                opponentNotification = notification;
            }
        }

        if (playerNotification || opponentNotification) {
            const translateText = getTranslations();

            if (playerNotification) {
                historyGameData.player.notification = translateText(playerNotification);
            } else if (opponentNotification) {
                historyGameData.opponent.notification = translateText(opponentNotification);
            }
        }

        store.dispatch(setMovePlayedData(historyGameData));

        store.dispatch(batchActions(actions));
    }
};

export function findNearestPreviousMove(currentRound, stepId) {
    let result;
    for (let i = stepId - 1; i >= 0; i--) {
        if (currentRound.stepList[i]?.moveList?.length) {
            result = currentRound.stepList[i].moveList[currentRound.stepList[i].moveList.length - 1];
            break;
        }
    }
    return result;
}

export function addConventionsLists(data) {
    const { rounds, gameConfiguration } = data;

    rounds.forEach((round, index) => {
        const { stepList } = round;
        const fistStep = stepList[0];

        if (fistStep?.oldDice) {
            const moveList = fistStep.moveList;
            moveList.unshift({
                board: round.board,
                dices: fistStep.dice?.isDouble
                    ? Array(4).fill(fistStep.dice?.dice1, 0, 3)
                    : [fistStep.dice.dice1, fistStep.dice.dice2]
            });
        }
        const prevRoundStepList = rounds[index - 1]?.stepList;
        const prevRoundLastStep = prevRoundStepList && prevRoundStepList[prevRoundStepList.length - 1];
        const prevRoundPenultimateStep = prevRoundStepList && prevRoundStepList[prevRoundStepList.length - 2];

        for (let i = 0; i < stepList.length; ++i) {
            let currentStep = stepList[i],
                prevStep = stepList[i - 1];

            if (index > 0 && i === 0) {
                if (!currentStep.stepDoubleStake) {
                    let prevRoundStepList = data.rounds[index - 1].stepList;
                    currentStep.stepDoubleStake = prevRoundStepList[prevRoundStepList.length - 1]?.stepDoubleStake;
                }
            }

            if (prevStep?.stepCube && !stepList[i].stepCube) {
                currentStep.stepCube = stepList[i - 1].stepCube;
            }
            if (prevStep?.stepDoubleStake && !stepList[i].stepDoubleStake) {
                currentStep.stepDoubleStake = stepList[i - 1].stepDoubleStake;
            }

            if (i === 0) {
                if (index === 0) {
                    currentStep.playerGameTime = gameConfiguration.gameTime;
                    currentStep.opponentGameTime = gameConfiguration.gameTime;
                } else {
                    if (prevRoundLastStep?.isPlayer) {
                        currentStep.playerGameTime = prevRoundLastStep?.gameTime;
                        currentStep.opponentGameTime = prevRoundLastStep?.opponentGameTime;
                    } else {
                        currentStep.opponentGameTime = prevRoundLastStep?.gameTime;
                        currentStep.playerGameTime = prevRoundLastStep?.playerGameTime;
                    }
                }
            } else if (i === 1) {
                if (index === 0) {
                    if (currentStep.isPlayer) {
                        currentStep.playerGameTime = gameConfiguration?.gameTime;
                        currentStep.opponentGameTime = prevStep?.gameTime;
                    } else {
                        currentStep.playerGameTime = prevStep?.gameTime;
                        currentStep.opponentGameTime = gameConfiguration?.gameTime;
                    }
                } else {
                    if (currentStep.isPlayer) {
                        currentStep.playerGameTime = prevRoundLastStep?.isPlayer
                            ? prevRoundLastStep?.gameTime
                            : prevRoundPenultimateStep?.gameTime;
                        currentStep.opponentGameTime = prevStep?.gameTime;
                    } else {
                        currentStep.playerGameTime = prevStep?.gameTime;
                        currentStep.opponentGameTime = prevRoundLastStep?.isPlayer
                            ? prevRoundPenultimateStep?.gameTime
                            : prevRoundLastStep?.gameTime;
                    }
                }
            } else {
                if (prevStep?.isPlayer) {
                    currentStep.playerGameTime = prevStep?.gameTime;
                    currentStep.opponentGameTime = prevStep.opponentGameTime;
                } else {
                    currentStep.opponentGameTime = prevStep?.gameTime;
                    currentStep.playerGameTime = prevStep?.playerGameTime;
                }
            }
        }
    });
    return data;
}

export function debounce(func, wait, immediate = true) {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

/**
 * Separate host
 * @returns {string}
 */
export function separateHost() {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    host = host.join(".");
    return host;
}

/**
 * update html lang attribute
 * @param lang
 */
export function updateDocumentLanguage(lang) {
    document.documentElement.lang = lang;
}

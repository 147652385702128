import { composeWithDevTools } from "redux-devtools-extension";
import { enableBatching } from "redux-batched-actions";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers";
import updateDataOnAnimationEnd from "./middlewares/updateDataOnAnimationEnd";
import keepGameUpToDate from "./middlewares/keepGameUpToDate";

export const store = createStore(
    enableBatching(rootReducer),
    composeWithDevTools(applyMiddleware(updateDataOnAnimationEnd, keepGameUpToDate))
);

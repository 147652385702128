import { HubConnectionBuilder, HttpTransportType, JsonHubProtocol, LogLevel } from "@microsoft/signalr";
import { HIDDEN, isHistory, VISIBILITY_CHANGE } from "../constants";

export let connection = null;

export const startSignalR = (
    url,
    connectionStart,
    messageListener,
    connectionLost,
    canStartConnection = true,
    clearIntervals
) => {
    connection = new HubConnectionBuilder()
        .withUrl(url, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        })
        .withAutomaticReconnect([0, 0, 1000])
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LogLevel.Information)
        .build();

    const handleStart = async reconnected => {
        try {
            console.log(">>>>>>  start");
            await connection.start();
            console.log(">>>>>>  started  ", connection);
            typeof connectionStart === "function" && (await connectionStart(connection, reconnected));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Error while establishing connection ${err}`);
            setTimeout(() => {
                handleStart();
            }, 1000);
        }
    };

    const offlineHandler = () => {
        if (connection) {
            console.log(">>>>>>>  stop");
            connection.stop();
            clearIntervals();
        }
    };

    window.addEventListener("offline", offlineHandler);

    const visibilityChangeHandler = async () => {
        if (!isHistory) {
            if (!document[HIDDEN]) {
                console.log(">>>>>>> visibility stop");
                connection.stop();
                clearIntervals();
            }
        }
    };

    window.addEventListener(VISIBILITY_CHANGE, visibilityChangeHandler, false);

    (() => {
        typeof messageListener === "function" && messageListener(connection);
        canStartConnection &&
            handleStart().then(() => {
                // eslint-disable-next-line no-console
                console.log("connected");
            });

        connection.onclose(async () => {
            typeof connectionLost === "function" && connectionLost(connection);
            window.removeEventListener("offline", offlineHandler);
            window.removeEventListener(VISIBILITY_CHANGE, visibilityChangeHandler);

            startSignalR(
                url,
                connectionStart,
                messageListener,
                connectionLost,
                (canStartConnection = true),
                clearIntervals
            );
            console.log(">>>>>>>  on close");
            // handleStart(true);
        });
    })();
};

export const invoke = (type, params) => {
    if (params !== undefined) {
        connection.invoke(type, params);
    } else {
        connection.invoke(type);
    }
};

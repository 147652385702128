import { APP_PROPERTIES, DEVICE_MODE, gameId, VALIDATION_COOKIE_NAME } from "../constants";
import { store } from "../store";
import getSymbolFromCurrency from "currency-symbol-map";

/**
 * Returns a parameter from Url
 * @param {string} paramsArr - The Url params
 * @return {object}
 */
export function getUrlParams(paramsArr) {
    let urlParams = {};
    const url = new URL(window.location.href);
    for (let i = 0, len = paramsArr.length; i < len; ++i) {
        urlParams[paramsArr[i]] = url.searchParams.get(paramsArr[i]);
    }
    return urlParams;
}

/**
 * Detect is playerInfo in the history mode
 * @return {boolean}
 */
export function isHistoryMode() {
    return window.location.pathname.includes("/game/history");
}

/**
 * Returns initial playerInfo data from Url
 * @return {object}
 */
export function getGameDataFromUrl() {
    let {
        gameId,
        lang,
        token,
        isMobile,
        playerId,
        consumerPartnerId,
        currencyId,
        win,
        bet,
        guestId,
        isBot,
        isDemo,
        platformId
    } = getUrlParams([
        "gameId",
        "playerId",
        "lang",
        "token",
        "isMobile",
        "consumerPartnerId",
        "currencyId",
        "win",
        "bet",
        "guestId",
        "isBot",
        "isDemo",
        "platformId"
    ]);
    isMobile = (isMobile && isMobile.toLowerCase()) === "true" || false;
    const isHistory = isHistoryMode();

    win = win?.replace(",", ".") * 1;
    bet = bet?.replace(",", ".") * 1;
    platformId = platformId || 1;

    return {
        gameId,
        playerId,
        token,
        lang,
        isMobile,
        isHistory,
        consumerPartnerId,
        currencyId,
        win,
        bet,
        guestId,
        isBot,
        isDemo,
        platformId
    };
}

/**
 * Determain is time in danger state
 * @param {number} extraTime
 * @param {number} userExtraTime
 * @return {boolean}
 */
export const isTimeDanger = (extraTime, userExtraTime) => {
    return extraTime / 4 > userExtraTime;
};

/**
 * Get timer starting second
 * @param {number} totalTime
 * @param {number} remainingTime
 * @return {number}
 */
export const getTimerStartingSecond = (totalTime, remainingTime) => {
    if (remainingTime < 0) {
        return totalTime;
    }
    return totalTime - remainingTime;
};

/**
 * Returns client width and height in pixels.
 * @return {object}
 */
export const getDocumentSize = () => {
    const { clientHeight, clientWidth } = document.documentElement;
    return {
        height: clientHeight,
        width: clientWidth
    };
};

/**
 * @param clientWidth
 * @param clientHeight
 * In base of client width and client height,
 * function return playerInfo Dimensions in (16/9),
 * aspect ration.
 * @return {object}
 */

export const calculateGameDimension = (clientWidth, clientHeight) => {
    let widthForFontSize = 0,
        finalDimensions = {};

    if (
        (clientWidth * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH <
        clientHeight
    ) {
        widthForFontSize = clientWidth;
        finalDimensions = {
            gameHeight:
                (clientWidth * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH,
            fontSize: widthForFontSize / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX,
            gameWidth: clientWidth
        };
    } else {
        widthForFontSize =
            (clientHeight * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT;
        finalDimensions = {
            gameWidth:
                (clientHeight * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT,
            fontSize: widthForFontSize / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX,
            gameHeight: clientHeight
        };
    }
    setRootFontSize(widthForFontSize);
    return { ...finalDimensions, clientWidth, clientHeight };
};

/**
 * @param gameColumnWidth
 * @param staticFontSize
 * @param type
 * In base of playerInfo width, function calculates
 * root font-size and sets on HTML tag.
 * If app is running in mobile mode,`
 * root font size must me 12.
 * @return {undefined}
 */
export const setRootFontSize = (gameColumnWidth, type, staticFontSize) => {
    const rootElement = document.getElementsByTagName("html")[0];
    if (type === DEVICE_MODE.MOBILE) {
        rootElement.style.fontSize = `${staticFontSize}px`;
        return;
    }
    rootElement.style.fontSize = `${gameColumnWidth / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX}px`;
};

/**
 * @param obj1
 * @param obj2
 * The function checks object
 * @return {boolean}
 */
export const isDeepEqual = (obj1, obj2) => {
    if (obj1 && obj1.constructor === "object" && obj2 && obj2.constructor === "object") {
        const objKeys1 = Object.keys(obj1),
            objKeys2 = Object.keys(obj2);
        if (objKeys1.length !== objKeys2.length) {
            return false;
        }
        for (let key of objKeys1) {
            if (objKeys2.includes(key)) {
                if (!isDeepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            } else {
                return false;
            }
        }
        return true;
    } else {
        return obj1 === obj2;
    }
};

/**
 * @param a
 * @param b
 * The function order object by their Value key
 */
export const mergeArrays = (a, b) => {
    for (let i = 0, len = a.length; i < len; ++i) {
        if (!b.includes(a[i])) {
            b.push(a[i]);
        }
    }

    return b;
};

/**
 * @param a
 * @param min
 * The function get all possible combos of array elements
 * @return {Array}
 */
export const getPossibleCombos = (a, min) => {
    const fn = (n, src, got, all) => {
        if (n === 0) {
            if (got.length > 0) {
                all[all.length] = got;
            }
            return;
        }
        for (let j = 0; j < src.length; ++j) {
            fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
        }
    };
    let all = [];
    for (let i = min; i < a.length; ++i) {
        fn(i, a, [], all);
    }
    all.push(a);
    return all;
};

/**
 * Returns a parameter from Url
 * @param {string} param - The Url param
 * @return {string}
 */
export function getUrlParam(param) {
    const urlString = window.location.href;
    const url = new URL(urlString);

    return url.searchParams.get(param);
}

export const ssHelper = {
    set: (key, data) => {
        try {
            window.sessionStorage.setItem(key + gameId, JSON.stringify(data));
        } catch (e) {
            console.log(e);
        }
    },
    get: key => {
        try {
            return (
                window.sessionStorage.getItem(key + gameId) && JSON.parse(window.sessionStorage.getItem(key + gameId))
            );
        } catch (e) {
            console.log(e);
        }
    },

    remove: key => {
        try {
            window.sessionStorage && window.sessionStorage.removeItem(key + gameId);
        } catch (e) {
            console.log(e);
        }
    },

    clear: () => {
        try {
            window.sessionStorage && window.sessionStorage.clear();
        } catch (e) {
            console.log(e);
        }
    }
};

export const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min) + min);

export const getLocalIp = () =>
    new Promise((resolve, reject) => {
        window.RTCPeerConnection =
            window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;

        if (typeof window.RTCPeerConnection === "undefined") return reject("WebRTC not supported by browser");

        let pc = new RTCPeerConnection();
        let ips = [];

        pc.createDataChannel("");
        pc.createOffer()
            .then(offer => pc.setLocalDescription(offer))
            .catch(err => reject(err));
        pc.onicecandidate = event => {
            if (!event || !event.candidate) {
                // All ICE candidates have been sent.
                if (ips.length === 0) return reject("WebRTC disabled or restricted by browser");

                return resolve(ips);
            }

            let parts = event.candidate.candidate.split(" ");
            let ip = parts[4];

            if (!ips.some(e => e === ip)) ips.push(ip);
        };
    });

export function getPartnerLogo(deckTypes = "default") {
    const partnerSpecificIds = [
        1
        // 11,
        // 27,
        // 29,
        // 30,
        // 34,
        // 37,
        // 43,
        // 44,
        // 50,
        // 51,
        // 57,
        // 58,
        // 60,
        // 61,
        // 62,
        // 63,
        // 64,
        // 65,
        // 69
    ];

    const setDeckTypes = name => {
        switch (name) {
            case "default":
                return "default";
            case "classic":
            case "armenian":
                return "classic";
        }
    };

    const id = Number(getUrlParam("consumerPartnerId"));

    return partnerSpecificIds.includes(id)
        ? `/assets/images/partnerSpecific/logos/${setDeckTypes(deckTypes)}/${id}.png  `
        : null;
}

export const httpService = ({
    url,
    method,
    body,
    headers = { "Content-Type": "application/json;charset=utf-8" },
    successHandler,
    errorHandler
}) => {
    fetch(url, {
        method,
        headers: {
            ...headers,
            [VALIDATION_COOKIE_NAME]: getValidationCookie()
        },
        body: body && JSON.stringify(body)
    })
        .then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        })
        .then(data => successHandler && successHandler(data))
        .catch(err => (errorHandler ? errorHandler(err) : console.error(err)));
};

export const getValidationCookie = () => {
    return localStorage.getItem(VALIDATION_COOKIE_NAME);
};

export function postCloseWindow() {
    window.parent.postMessage("closeWindow", "*");
}

export const getCurrencySign = currency => {
    return getSymbolFromCurrency(currency) || currency;
};

export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function debounce(func, wait, immediate = true) {
    let timeout;

    return function executedFunction() {
        const { startStoneAnimation } = store.getState().gameData;
        const context = this;
        const args = arguments;

        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow && !startStoneAnimation) func.apply(context, args);
    };
}

export const getDomain = () => {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    return host.join(".");
};

/**
 * getBrowserSpecificProperties
 * @returns {object}
 */
export function getBrowserSpecificProperties() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    return { HIDDEN: hidden, VISIBILITY_CHANGE: visibilityChange };
}

/**
 * Separate host
 * @returns {string}
 */
export function separateHost() {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    host = host.join(".");
    return host;
}

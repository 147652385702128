import { setSelectedStonePossibleMoves, setStoneFromColumn, setStoneToColumn, toggleStoneAnimation } from "../actions";
import { SET_MOVE_PLAYED_DATA, TOGGLE_STONE_ANIMATION } from "../actions/actionTypes";
import { batchActions } from "redux-batched-actions";
import { HIDDEN, isHistory } from "../../constants";

export default function updateDataOnAnimationEnd({ getState }) {
    // eslint-disable-next-line no-unused-vars
    let animationInProcess = false;
    let nextActions = [];
    let currentMovePlayed = null;
    let currentActions = [];
    let actionsBeforeGameData = [];
    let nextActionTimeoutIds = [];
    return next => action => {
        const { type, payload } = action;
        const fullMove = payload?.fullMove;
        const { gameData } = getState();

        // fullMove.from < 24 is for disabling animation on hit stones and taken stones
        if (
            (isHistory || (gameData.playerConfiguration?.showAnimations && !payload?.undo)) &&
            type === SET_MOVE_PLAYED_DATA &&
            fullMove?.from < 24 &&
            fullMove?.to < 24 &&
            !document[HIDDEN]
        ) {
            if (animationInProcess) {
                nextActions.push(action);
                nextActionTimeoutIds.push(
                    setTimeout(() => {
                        nextActions.length && next(batchActions(nextActions));
                        nextActions = [];
                    }, 2000)
                );
            } else {
                animationInProcess = true;
                currentMovePlayed = action;
                next(
                    batchActions([
                        setStoneFromColumn(fullMove.from),
                        setStoneToColumn(fullMove.to),
                        toggleStoneAnimation(true),
                        setSelectedStonePossibleMoves(null)
                    ])
                );
            }
            return;
        } else if (type === TOGGLE_STONE_ANIMATION && !payload && animationInProcess) {
            animationInProcess = false;
            currentMovePlayed && next(currentMovePlayed);
            currentMovePlayed = null;

            if (nextActions.length) {
                const nextAction = nextActions.shift();
                animationInProcess = true;
                currentMovePlayed = nextAction;
                clearTimeout(nextActionTimeoutIds.shift());
                setTimeout(() => {
                    next(
                        batchActions([
                            setStoneFromColumn(nextAction.payload.fullMove.from),
                            setStoneToColumn(nextAction.payload.fullMove.to),
                            toggleStoneAnimation(true),
                            setSelectedStonePossibleMoves(null)
                        ])
                    );
                }, 100);
            } else if (currentActions.length) {
                next(batchActions(currentActions));
                currentActions = [];
            }
            return;
        } else if (animationInProcess) {
            currentActions.push(action);
            return;
        }

        if (actionsBeforeGameData.length) {
            setTimeout(() => {
                next(batchActions(actionsBeforeGameData));
                actionsBeforeGameData = [];
            });
        }

        if (type === SET_MOVE_PLAYED_DATA && !gameData.gameDataIsSet && !isHistory) {
            return actionsBeforeGameData.push(action);
        }

        return next(action);
    };
}

import { store } from "../store";

const defaultTexts = {
    Accept: "Accept",
    Agree: "Agree",
    AutoRoll: "Auto Roll",
    AutoSubmit: "Auto-Submit",
    Background: "Background",
    BaseStake: "Base Stake",
    Beaver: "Beaver",
    Board: "Board",
    Cancel: "Cancel",
    Change: "Change ",
    ChangeDice: "Dice Change",
    Chips: "Checkers",
    ChooseCubeOption: "Please choose cube option, if you decline the offer you will lose this round.",
    ChooseDice: "Please choose  dice to change.",
    ChooseOption: "Please choose an option.",
    Confirm: "Confirm",
    ConnectionLost: "Connection Lost",
    Cube: "Cube",
    CubeRequest: "Cube request",
    CurrentStake: "Current Stake",
    Deny: "Deny",
    Dice: "Dices",
    DicePrice: "Dice Price",
    Disagree: "Disagree",
    DoYouWantToDoubleScore: " Do you want to double your score?",
    DoYouWantToDoubleStake: "Do you want to double your stake?",
    DoYouWantToExitGame: "If you leave the game, you will lose. Are you sure you want leave?",
    Double: "Double",
    DoubleStake: "Double Stake",
    DoubleStakeOffer: "Your opponent offers to double the stake. Otherwise you will lose.",
    DoubleStakeRequest: "Double stake request",
    EntranceFee: "Buy-in",
    Game: "Game",
    GameId: "Game ID",
    HistoryFinishRoundKoks: "Round finished with Koks.",
    HistoryFinishRoundMars: "Round finished with Mars.",
    HistoryFinishRoundNormal: "Round finished.",
    JacobyRule: "Jacoby Rule",
    Koks: "Koks",
    Leave: "Leave",
    LeaveGame: "Leave Game",
    LeaveMatch: "Leave Match",
    LeftClick: "The left click button plays the bigger number",
    Mars: "Mars",
    Match: "Match",
    MaxStake: "Max Stake",
    MiddleClick: "The scroll button plays the sum of both numbers",
    Ok: "Ok",
    OpponentDoesntAgree: "Your opponent doesn't agree",
    OpponentLeavesGame: "Opponent Leaves The Game",
    Price: "Price:",
    PrivateGame: "Private Game",
    Prize: "Prize",
    RangeTotalBonus: "Range Total Bonus",
    Recommend: "Recommend",
    Redouble: "Redouble",
    Reject: "Reject",
    Remain: "Remain",
    RightClick: "The right click button plays the smaller number",
    Roll: "Roll",
    RotateBoard: "RotateBoard",
    Round: "Round",
    Save: "Save",
    Score: "Score",
    Settings: "Settings",
    ShowAnimations: "Show Animations",
    ShowPossibleMoves: "Show Possible Moves",
    Stay: "Stay",
    SumOfTotalBonuses: "Sum of Total Bonuses",
    Surrender: "Surrender",
    TimeOut: "Time out",
    ToEnd: "Game Over",
    TournamentId: "Tournament ID",
    Undo: "Undo",
    WantsToSurrend: "Your opponent wants to surrender with #",
    YourFinalStake: "Your final Stake after doubling:"
};

export function getTranslations() {
    const translations = store.getState().translations;
    return key => translations[key] || (defaultTexts && defaultTexts[key]) || key;
}

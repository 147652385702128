import { SET_TRANSLATIONS } from "../actions/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TRANSLATIONS:
            return { ...state, ...payload };
        default:
            return state;
    }
};

import { SOCKET_INVOKE_ACTIONS, TURN_CHANGE_MESSAGES } from "../../constants";
import { connection } from "../../connection";
import { SET_END_GAME_DATA, SET_GAME_DATA } from "../actions/actionTypes";

export default function keepGameUpToDate() {
    let timeoutId = null;
    const forceGetGameData = isGameFinished => {
        clearTimeout(timeoutId);
        if (!isGameFinished) {
            timeoutId = setTimeout(() => {
                connection.invoke(SOCKET_INVOKE_ACTIONS.GET_GAME_DATA).catch(err => console.log(err, "err"));
            }, 20000);
        }
    };
    return next => action => {
        const { type, payload } = action;

        const isPayloadArray = Array.isArray(payload);
        if (
            type === SET_GAME_DATA ||
            TURN_CHANGE_MESSAGES[type] ||
            (isPayloadArray && payload.find(i => i.type === SET_GAME_DATA || TURN_CHANGE_MESSAGES[i.type]))
        ) {
            console.log(">>>>>>  right case");
            forceGetGameData(type === SET_END_GAME_DATA);
        }

        return next(action);
    };
}

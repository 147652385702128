import { Howl } from "howler";

const chipSound = new Howl({
    src: ["/assets/sounds/move_chip.mp3"],
    sprite: {
        move: [150, 1000]
    },
    pool: 0
});

const rollSound = new Howl({
    src: ["/assets/sounds/roll_dice.mp3"],
    pool: 0
});

export const playChipSound = () => chipSound.play("move");
export const playRollSound = () => rollSound.play();

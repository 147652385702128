import {
    SET_CURRENT_MOVE_ID,
    SET_CURRENT_ROUND_ID,
    SET_CURRENT_STEP_ID,
    SET_HISTORY_DATA
} from "../actions/actionTypes";

export const historyInitialState = {
    data: {},
    currentRound: 0,
    currentStep: 0,
    currentMove: -1
};

export default function historyData(state = historyInitialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_HISTORY_DATA:
            return {
                ...state,
                data: payload
            };
        case SET_CURRENT_ROUND_ID:
            return {
                ...state,
                currentRound: payload
            };
        case SET_CURRENT_STEP_ID:
            return {
                ...state,
                currentStep: payload
            };
        case SET_CURRENT_MOVE_ID:
            return {
                ...state,
                currentMove: payload
            };
        default:
            return state;
    }
}

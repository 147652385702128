import { SET_GAME_STATIC_DATA } from "../actions/actionTypes";

const initialState = null;

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_GAME_STATIC_DATA:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
};
